.layout {
  height: 100%;
  min-height: 100vh;

  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  background-color: #FFF;

  @media (max-width: 991px) {
    grid-template-rows:  1fr auto;
    main {
      margin-top: 80px;
    }
  }
}

main {
  padding-top: 36px;
}

.overlay-loader {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.no-scroll {
  overflow: hidden;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 960px) {
    max-width: 100%;
    padding: 0 15px;
  }
}

.mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: .6;
}

.tickets-block {
  padding-top: 120px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 20px;
}

.button-up {
  background-color: transparent;
  border: none;
  padding: 0;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.20);
  border-radius: 50%;

  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 99990;
  transition: 0.2s;

  svg {
    width: 100%;
    height: 100%;
    color: #ccf919;
    transition: 0.2s;
  }

  &:hover {
    svg {
      color: #e0431e;
    }
  }

  &.hide {
    display: none;
  }
}