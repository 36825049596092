.speech {
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 85px;
  padding: 60px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #c6c6c6;
  }

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    row-gap: 40px;
    padding: 10px 0 40px 0;
  }

  &-page {
    padding: 0;
  }

  &-photo {
    width: 100%;
    height: 390px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      z-index: 1;

      @media (max-width: 991px) {
        object-fit: contain;
      }
      @media (max-width: 480px) {
        object-fit: cover;
      }
    }

    &__background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: -10px;
      left: -10px;
      background-color: black;
      z-index: 0;
    }
  }

  &__section {
    width: 272px;
    height: 48px;
    grid-column-start: 1;
    grid-column-end: 3;
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 60px;

    color: #000000;
    font-size: 16px;
    line-height: 1.55;
    font-weight: 400;

    position: relative;

    margin-bottom: 20px;

    @media (max-width: 991px) {
      grid-column-end: unset;
      margin: 30px 0 10px 0;
    }

    &::before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 0 auto;
      background: transparent;
      border-top: 24px solid transparent;
      border-left: 24px solid #fff;
      border-bottom: 24px solid transparent;
      border-right: 24px solid transparent;
      position: absolute;
      top: 0;
      left: -1px;
    }
  }

  &-info {
    max-width: 640px;

    a {
      text-decoration: none;
    }
  }

  &-name {
    color: #000;
    font-size: 38px;
    line-height: 1.3;
    font-weight: 700;
    font-style: italic;
    margin-top: 40px;
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
  &-description {
    margin-top: 40px;
    color: #000000;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 400;

    h5 {
      font-size: 20px;
      line-height: 1.55;
      font-weight: 700;
      margin: 20px 0;
    }
    p {
      margin: 20px 0;
    }
    ul {
      padding-left: 22px;
    }
    li {
      margin: 12px 0;
    }
    b {
      font-weight: 600;
    }
    i {
      font-style: italic;
    }
  }

  &-materials {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px;

    @media (max-width: 991px) {
      grid-template-columns: 1fr;
    }

    &__alert {
      grid-column-start: 1;
      grid-column-end: 3;
      @media (max-width: 991px) {
        grid-column-end: unset;
      }
    }
  }
}

.speaker {
  &-name {
    color: #000000;
    font-size: 40px;
    line-height: 1.3;
    font-weight: 700;
    text-transform: uppercase;
    font-style: italic;
    @media (max-width: 480px) {
      font-size: 22px;
    }
  }
  &-city {
    color: #000000;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    @media (max-width: 480px) {
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.speakers-list {

}