.footer {
  background-color: #000;
  padding-top: 28px;
  padding-bottom: 45px;

  @media (min-width: 480px) {
    padding-top: 34px;
    padding-bottom: 34px;
  }

  @media (min-width: 640px) {
    padding-top: 41px;
    padding-bottom: 29px;
  }

  @media (min-width: 960px) {
    padding-top: 74px;
    padding-bottom: 51px;
  }

  &-wrapper {
    display: grid;
    grid-template-areas:
            "logo"
            "text"
            "menu"
            "contacts"
            "emails"
            "links"
            "rights";

    @media (min-width: 480px) {
      grid-template-areas:
              "logo text"
              "menu contacts"
              "emails emails"
              "links links"
              "rights rights";
    }
    @media (min-width: 640px) {
      grid-template-areas:
              "logo text"
              "menu contacts"
              "a emails"
              "links links"
              "rights rights";
    }
    @media (min-width: 960px) {
      grid-template-areas:
              "logo a b"
              "text menu contacts"
              "c d emails"
              "rights links links" ;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__logo {
    grid-area: logo;

    @media (min-width: 640px) {
      align-self: center;
    }
    @media (min-width: 960px) {
      align-self: flex-start;
    }

    img {
      width: 125px;
      height: 32px;
      object-fit: contain;
    }
  }

  &__text {
    grid-area: text;

    font-size: 26px;
    font-style: italic;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;

    margin-top: 16px;

    @media (min-width: 480px) {
      margin-top: 0;
    }

    @media (min-width: 960px) {
      margin-top: 37px;
    }
  }

  &__menu {
    grid-area: menu;

    display: flex;
    flex-direction: column;
    row-gap: 7px;

    margin-top: 23px;
    @media (min-width: 480px) {
      margin-top: 30px;
    }
    @media (min-width: 960px) {
      margin-top: 0;
    }
  }

  &__contacts {
    grid-area: contacts;

    display: flex;
    flex-direction: column;
    row-gap: 7px;

    margin-top: 4px;
    @media (min-width: 480px) {
      margin-top: 30px;
    }
    @media (min-width: 960px) {
      margin-top: 0;
    }
  }

  &-title {
    font-size: 18px;
    font-style: italic;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: #E0431E;
    text-transform: uppercase;

    @media (min-width: 960px) {
      font-size: 30px;
      margin-bottom: 25px;
    }
  }

  &-link {
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    text-decoration: none;

    &--organizator {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      text-decoration: none;
      color: #FF0000;
    }
  }

  &-socials {
    display: flex;
    gap: 17px;
    margin-top: 7px;
  }

  &__social {
    svg {
      width: 30px;
      height: 30px;
    }
  }

  &__emails {
    grid-area: emails;

    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #6C6C6C;

    margin-top: 26px;

    @media (min-width: 480px) {
      margin-top: 55px;
    }

    @media (min-width: 640px) {
      margin-top: 10px;
    }
  }

  &__links {
    grid-area: links;

    display: grid;
    grid-template-columns: 1fr;
    row-gap: 23px;

    margin-top: 23px;

    @media (min-width: 480px) {
      margin-top: 35px;
      row-gap: 19px;
    }

    @media (min-width: 640px) {
      margin-top: 55px;
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 960px) {
      margin-top: 47px;
    }
  }

  &__rights {
    grid-area: rights;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 0.5);

    margin-top: 43px;

    @media (min-width: 480px) {
      margin-top: 19px;
    }

    @media (min-width: 640px) {
      margin-top: 22px;
    }
    @media (min-width: 960px) {
      margin-top: 47px;
    }
  }
}