.modal-content {
  border-radius: 0 !important;
}

.recovery-password-link {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5;
  text-align: left;
  color: #000;
  text-decoration: none;
  transition: 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: #E0431E;
  }
}

.input {
  &-field {
    height: 48px;
    border-radius: 0 !important;

    &:focus {
      border-color: #E0431E;
      box-shadow: none
    }
  }
  &-label {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.5;
    text-align: left;
    color: #000;
  }
}

.recovery-password {
  max-width: 560px;
  padding: 40px 20px;
  margin: auto;
}