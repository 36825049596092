.links-wrapper {
  margin-top: 60px;
}

.live-link {
  display: block;
  margin: 20px 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.5;
  text-align: left;
  color: #000;
  text-decoration: none;
  transition: 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: #E0431E;
  }
}

.bot-info-link {
  font-weight: 500;
  letter-spacing: 0;
  color: #000;
  text-decoration: none;
  transition: 0.2s;

  &:hover,
  &:focus,
  &:active {
    color: #E0431E;
  }
}