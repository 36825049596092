.header {
  width: 100%;
  height: 80px;
  background-color: #000000;
  display: flex;
  align-items: center;
  z-index: 3;

  @media (max-width: 991px) {
    position: fixed;
    top: 0;
    left: 0;
  }

  &__logo {
    width: 99px;
    height: 27px;
    object-fit: contain;
    object-position: left;

    @media (max-width: 991px) {
      height: 20px;
    }
  }

  &__menu-links {
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 30px;

    @media (max-width: 991px) {
      flex-direction: column;
      row-gap: 20px;
      margin-left: 0;
      align-items: flex-start;
    }
  }
}

.menu-link {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  color: #fff;
  text-align: left;
  text-decoration: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 20%;
    opacity: 0;
    width: 100%;
    height: 100%;
    border-bottom: 0px solid #e0431e;
    -webkit-box-shadow: inset 0px -1px 0px 0px #e0431e;
    -moz-box-shadow: inset 0px -1px 0px 0px #e0431e;
    box-shadow: inset 0px -1px 0px 0px #e0431e;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  &:hover {
    &::after {
      opacity: 1;
      bottom: 0px;
    }
  }

  @media (max-width: 991px) {
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.burger-button {
  width: 48px;
  height: 48px;
  background-color: #e0431e;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    width: 22px;
    opacity: 1;
    height: 2px;
    background-color: #fff;
  }
}

.side-menu {
  transform: translateX(281px);
  transition: 0.2s;

  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 261px;
  background: #000000;
  padding: 42px 25px 25px;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.active {
    transform: translate(0);
  }

  &__close {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 15px;
    right: 17px;
  }

  &__text {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
  }

  &__socials {
    display: flex;
    gap: 4px;
    margin-top: 26px;
  }
}

.social {
  color: #fff;
  transition: 0.2s;

  svg {
    width: 30px;
    height: 30px;
  }

  &:hover {
    color: #e0431e;
  }
}

.profile-button {
  padding: 0;
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 12px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0em;
  color: #fff;
  text-align: left;
  text-decoration: none;
  position: relative;
  transition: 0.2s;

  svg {
    width: 31px;
    min-width: 31px;
    height: 30px;
  }

  &:hover, &:focus, &:active {

    .profile-button__menu {
      transform: scaleY(100%);
    }
  }

  @media (max-width: 991px) {
    margin-left: 0;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #e0431e;
  }

  &__menu {
    position: absolute;
    top: calc(100% + 0px);
    padding-top: 12px;
    right: 0;
    width: 200px;
    z-index: 1;
    transition: 0.2s;
    transform-origin: top;
    transform: scaleY(0);

    @media (max-width: 991px) {
      transform: scaleY(100%);
      position: static;
      padding-top: 0;
      margin-top: -12px;
    }
  }

  &__link {
    display: block;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0em;
    color: #fff;
    text-align: left;
    text-decoration: none;
    position: relative;
    transition: 0.4s;
    background-color: #e0431e;
    cursor: pointer;

    &:hover, &:focus, &:active {
      color: #000;
    }

    @media (max-width: 991px) {
      padding: 6px 0;
      padding-left: 42px;
      background-color: transparent;

      &:hover, &:focus, &:active {
        color: #e0431e;
      }
    }
  }
}
.divider {
  width: 100%;
  height: 1px;
  background: #fff;
}