@use "./typography.scss";
@use "./layout.scss";
@use "./components/Header.scss";
@use "./components/Footer.scss";
@use "./components/ProgramGrid.scss";
@use "./components/SpeechCard.scss";
@use "./components/Live.scss";
@use "./components/Auth.scss";

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background: #C7C7C7;
    padding: 0 1px;
  }
  &::-webkit-scrollbar-thumb {
    border: 1px solid #C7C7C7;
    background: #FFF;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 100%;
  text-decoration: none;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.55;
  font-weight: 500;
  background-color: #e0431e;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

  &:hover {
    background-color: #000 !important;
  }

  &:disabled {
    background-color: #696969;
    &:hover {
      background-color: #696969 !important;
    }
  }
}

.back-button {
  display: flex;
  align-items: center;
  gap: 20px;
  color: #000;
  font-size: 20px;
  line-height: 1.55;
  font-weight: 500;
  text-decoration: none;
  &::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-top: 2px solid #e0431e;
    border-left: 2px solid #e0431e;
    transform: rotate(-45deg);
  }
}