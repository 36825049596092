.program-grid {
  &-wrapper {
    position: relative;
    max-width: 100%;
    overflow-x: auto;
  }

  th, td {
    border: 1px solid #000;
    height: 100%;
    padding: 12px;

    &:nth-child(1) {
      position: -webkit-sticky;
      position: sticky;
      background-color: white;
      left: 0;
      z-index: 1;
      border-left: none;
      border-right: none;
      &::after {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        position: absolute;
        left: 0; top: 0;
        background-color: #000;
      }
      &::before {
        content: '';
        display: block;
        height: 100%;
        width: 1px;
        position: absolute;
        right: -1px; top: 0;
        background-color: #000;
      }
    }
  }

  &-time {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
    @media (max-width: 480px) {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
      white-space: pre-wrap;
    }
  }

  th {
    vertical-align: middle;
  }
  td {
    vertical-align: baseline;
  }

  &-section, &-program {
    min-width: 250px;
    position: relative;
    vertical-align: top;
    @media (max-width: 480px) {
      min-width: 150px;
    }
    &__name {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      @media (max-width: 480px) {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        white-space: pre-wrap;
      }
    }
    &__location {
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      @media (max-width: 480px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        white-space: pre-wrap;
      }
    }

    &__color {
      position: absolute;
      bottom: 0; left: 0; right: 0;
      width: 100%;
      height: 7px;
    }

    a {
      color: unset;
      text-decoration: none;
    }
  }
}

.program-card {
  position: relative;
  padding: 46px 16px !important;

  &__color {
    position: absolute;
    top: 0; left: 0; right: 0;
    width: 100%;
    height: 7px;
  }

  &__speaker {
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__company {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  &__speech {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

    margin-top: 10px;
  }
}

.category-select {
  margin-top: 35px;
  margin-bottom: 69px;

  &--sticky {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
    padding-top: 35px;
    padding-bottom: 45px;
    border-bottom: 1px solid #c6c6c6;
    .category-select {
      margin: 0;
    }

    @media (max-width: 991px) {
      top: 80px;
      padding-top: 25px;
      padding-bottom: 25px;
    }

    .category-select__sections {
      @media (max-width: 991px) {
        max-height: 0;
        overflow: hidden;
        transition: 0.4s;
      }
      &.active {
        @media (max-width: 991px) {
          max-height: 100vh;
          padding-top: 20px;
          padding-bottom: 12px;
        }
      }
    }
    .category-select__title {
      @media (max-width: 991px) {
        margin-bottom: 0;
      }
    }
  }

  &__expand {
    padding: 12px 0;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    column-gap: 16px;

    @media (min-width: 991px) {
      display: none;
    }

    svg {
      width: 18px;
      height: 18px;
      fill: #595959;
      transition: 0.2s;
    }

    &::before {
      content: 'Развернуть';
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      text-align: left;
      color: #595959;
      transition: 0.2s;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
      &::before {
        content: 'Свернуть';
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    margin-bottom: 23px;
  }

  &__sections {
    display: flex;
    column-gap: 13px;
    row-gap: 13px;
    flex-wrap: wrap;
    @media (min-width: 960px) {
      column-gap: 20px;
    }
  }

  &__button {
    padding: 7px 13px;
    border: 5px solid #E0431E;
    background-color: #fff;

    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;

    transition: 0.2s;
  }
}
#wrapper1 {
  position: sticky;
  top: -1px;
  z-index: 2;
  @media (max-width: 991px) {
    top: 80px;
  }
}
.double-scroll {
  height: 1px;
}

.conference {
  &-date {
    color: #000;
    font-size: 18px;
    line-height: 1.55;
    font-weight: 300;
    background-position: center center;
    border-color: transparent;

    @media (min-width: 640px) {
      font-size: 22px;
    }

    @media (min-width: 960px) {
      font-size: 30px;
    }

    span {
      display: inline-flex;
      align-items: center;
      &:first-child {
        &::after {
          content: '';
          display: inline-block;
          height: 18px;
          width: 2px;
          background-color: #E0431E;
          margin: 0 8px;

          @media (min-width: 640px) {
            height: 22px;
            margin: 0 12px;
          }

          @media (min-width: 960px) {
            height: 30px;
            margin: 0 12px;
          }
        }
      }
    }
  }
  &-location {
    color: #000;
    font-size: 14px;
    line-height: 1.55;
    font-weight: 400;
    text-transform: uppercase;
    background-position: center center;
    border-color: transparent;

    @media (min-width: 640px) {
      font-size: 16px;
    }

    @media (min-width: 960px) {
      font-size: 18px;
    }
  }
}