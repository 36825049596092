.h1 {
  font-size: 38px;
  font-style: italic;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;

  @media (min-width: 991px) {
    font-size: 80px;
    font-style: italic;
    font-weight: 700;
    line-height: 90px;
    max-width: 640px;
  }
}